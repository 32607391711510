import Header from '../Header/Header';
import './Layout.scss';

const Layout = ({children}) => {
    return (
        <div className="App">
            <Header />
            <div className='page'>
                {children}
            </div>
        </div>
    )
}

export default Layout