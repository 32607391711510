import React from 'react';
import SkillItem from './SkillItem';

const SkillsCategory = ({ title, skills }) => {
  const categoryClass = title.toLowerCase().split(' ').join('-');
  return (
    <div className={`skills-category ${categoryClass}`}>
      <h2 className="skills-title">{title}</h2>
      <div className="skills-list">
        {skills.map((skill, index) => (
          <SkillItem key={index} name={skill.name} logo={skill.logo} />
        ))}
      </div>
    </div>
  );
};

export default SkillsCategory;