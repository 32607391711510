import React, { useEffect, useState, useRef } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import './Header.scss';
import Logo from '../../assets/images/transparent_logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode, faEnvelope, faHome, faUser } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons';

const Header = () => {
    const location = useLocation();
    const isHomePage = location.pathname === '/';
    const navBarRef = useRef(null);
    const [activeSection, setActiveSection] = useState('');
    const [headerHeight, setHeaderHeight] = useState(0);

    useEffect(() => {
        if (isHomePage) {
            const handleScroll = () => {
                const introPosition = document.getElementById('intro').getBoundingClientRect();
                const projectsPosition = document.getElementById('projects').getBoundingClientRect();

                if (projectsPosition.top <= window.innerHeight && projectsPosition.bottom >= 0) {
                    setActiveSection('projects');
                } else if (introPosition.top <= window.innerHeight && introPosition.bottom >= 0) {
                    setActiveSection('home');
                } else {
                    setActiveSection('');
                }
            };

            window.addEventListener('scroll', handleScroll);
            return () => window.removeEventListener('scroll', handleScroll);
        }
    }, [isHomePage]);

    useEffect(() => {
        setHeaderHeight(navBarRef.current.offsetHeight);
    }, []);

    const scrollWithOffset = (el) => {
        const y = el.getBoundingClientRect().top + window.pageYOffset - headerHeight;
        window.scrollTo({ top: y, behavior: 'smooth' });
    };

    return (
        <div className='nav-bar' ref={navBarRef}>
            <Link className='logo' to='/'>
                <img src={Logo} alt="logo" />
            </Link>
            <nav>
                {isHomePage ? (
                    <>
                        <HashLink to="#intro"
                            className={`home-icon ${activeSection === 'home' ? 'active' : ''}`}>
                            <FontAwesomeIcon icon={faHome} color={activeSection === 'home' ? 'cyan' : '#8f8f8f'} />
                        </HashLink>
                        <HashLink
                            to="#projects"
                            className={`projects-icon ${activeSection === 'projects' ? 'active' : ''}`}
                            scroll={scrollWithOffset}
                        >
                            <FontAwesomeIcon icon={faCode} color={activeSection === 'projects' ? 'cyan' : '#8f8f8f'} />
                        </HashLink>
                    </>
                ) : (
                    <>
                        <NavLink to="/#intro" className="home-icon">
                            <FontAwesomeIcon icon={faHome} color="#8f8f8f" />
                        </NavLink>
                        <NavLink to="/#projects" className="projects-icon">
                            <FontAwesomeIcon icon={faCode} color="#8f8f8f" />
                        </NavLink>
                    </>
                )}
                <NavLink to="/about" className={({ isActive }) =>
                    `about-link ${isActive ? 'active' : ''}`}>
                    <FontAwesomeIcon icon={faUser} color="#8f8f8f" />
                </NavLink>
                <NavLink to={`${isHomePage ? "#contact" : "/#contact"}`} className={({ isActive }) =>
                    `contact-link ${isActive ? 'active' : ''}`}>
                    <FontAwesomeIcon icon={faEnvelope} color="#8f8f8f" />
                </NavLink>
            </nav>
            <ul>
                <li>
                    <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/christopher-leu-28bba6147/">
                        <FontAwesomeIcon icon={faLinkedin} color="#8f8f8f" />
                    </a>
                </li>
            </ul>
        </div>
    );
}

export default Header;