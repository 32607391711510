import React from 'react';

const SkillItem = ({ name, logo }) => {
  return (
    <div className="skill-item">
      <img src={logo} alt={name} className="skill-logo" />
      <p className="skill-name">{name}</p>
    </div>
  );
};

export default SkillItem;