import React, { useEffect, useRef, useState } from 'react';
import * as THREE from 'three';
import WAVES from 'vanta/dist/vanta.waves.min';

function VantaBackground() {
    const [vantaEffect, setVantaEffect] = useState(0);
    const vantaRef = useRef(null);

    useEffect(() => {
        if (!vantaEffect) {
            setVantaEffect(WAVES({
                el: vantaRef.current,
                THREE: THREE,
                mouseControls: true,
                touchControls: true,
                gyroControls: false,
                minHeight: 200.00,
                minWidth: 200.00,
                scale: 1.00,
                scaleMobile: 1.00,
                color: 0x313131,
                shininess: 5.00,
                waveHeight: 6.50,
                waveSpeed: 0.45,
                zoom: 0.85
            }));
        }
        return () => {
            if (vantaEffect) vantaEffect.destroy();
        };
    }, [vantaEffect]);

    return <div ref={vantaRef} style={
        {
            width: '100%',
            height: '61.8%',
            position: 'absolute',
            top: 0, left: 0,
            zIndex: -1
        }
    }>

    </div>;
}

export default VantaBackground;
