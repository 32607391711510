import React, { useEffect } from 'react';
import Intro from './Intro/Intro';
import Projects from './Projects/Projects';
import Skills from './Skills/Skills';
import Contact from './Contact/Contact';
import Footer from './Footer/Footer';

const HomePage = () => {
    useEffect(() => {
        const hash = window.location.hash;
        if (hash) {
            const id = hash.replace('#', '');
            const element = document.getElementById(id);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, []);

    return (
        <>
            <div id="intro"><Intro /></div>
            <div id="projects"><Projects /></div>
            <div id="skills"><Skills /></div>
            <div id="contact"><Contact /></div>
            <div id="footer"><Footer /></div>
        </>
    )
}

export default HomePage;