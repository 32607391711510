import React, { useState, useEffect } from 'react';
import './Intro.scss';
import VantaBackground from "../VantaBackground"

const Home = () => {
    const fullText = "Hello! I'm a Software Engineer with experience in full stack development.";
    const [typedText, setTypedText] = useState('');
    const [cursorVisible, setCursorVisible] = useState(true);

    useEffect(() => {
        let index = 0;
        const typeCharacter = () => {
            if (index < fullText.length) {
                index++;
                setTypedText(fullText.slice(0, index));
            } else {
                clearInterval(typingInterval);
            }
        };

        const typingInterval = setInterval(typeCharacter, 50);
        const cursorBlinkInterval = setInterval(() => {
            setCursorVisible(vis => !vis);
        }, 500);

        return () => {
            clearInterval(cursorBlinkInterval);
        };
    }, [fullText]);

    return (
        <>
            <VantaBackground />
            <div id="intro" className="container intro-section" >
                <h1 > Christopher <br /> Leu </h1>
                <div className="command-line-wrapper">
                    <span className="prompt">C:\ChrisLeu&gt; </span>
                    <span className="typing-animation">{typedText}</span>
                    <span className="cursor">{cursorVisible ? '_' : ' '}</span>
                </div>
            </div>
        </>
    );
};

export default Home;