import React from 'react';
import ProjectCard from './ProjectCard';
import './Projects.scss';
import vpinLogo from "../../assets/images/vpin_snap.png";
import atmLogo from "../../assets/images/atm_snap.png";

const projectsData = [
    {
        id: 1,
        title: "Vpin (OSparks)",
        description: "Content and recommendation microservice. Created content can be found through the link, or with the #1hour_smarter tag. Code snippets and writeup coming soon pending approval.",
        image: vpinLogo,
        url: "https://www.vpin.club/channel/bf0b40bf-a34e-473b-ab44-9a3ae03e0b70",
        tag: [],
    },
    {
        id: 2,
        title: "ATM Website",
        description: "HTML/CSS Website for Intro to Web Design course.",
        image: atmLogo,
        url: "https://christopherleu.com/iwd-final/",
        tag: [],
    },
]
const Projects = () => {
    return (
        <div id="projects" className='container projects-page'>
            <h2>PROJECTS</h2>
            <div className='projects'>{projectsData.map((project) => (
                <ProjectCard
                    key={project.id}
                    title={project.title}
                    description={project.description}
                    url = {project.url}
                    imgUrl={project.image}
                />
            ))}
            </div>
        </div>
    )
}

export default Projects