import React from "react";
import './Footer.scss';
import { HashLink } from 'react-router-hash-link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';

const Footer = () => {
    return (
        <div className="footer">
            <div className="footer-links">
                <HashLink to="#intro">Home</HashLink>
                <HashLink to="#projects">Projects</HashLink>
                <HashLink to="#skills">Skills</HashLink>
                <HashLink to="#about">About</HashLink>
            </div>
            <div className="footer-info">
                <p>Christopher Leu - <a href="mailto:contact@christopherleu.com">contact@christopherleu.com</a>
                    <HashLink to="#intro" className="back-to-top">
                        <FontAwesomeIcon icon={faArrowUp} />
                    </HashLink>
                </p>
            </div>
            <p className="footer-copy">© {new Date().getFullYear()} Christopher Leu. All rights reserved.</p>
        </div>
    );
};

export default Footer;