import './AboutIntro.scss';

const AboutIntro = () => {

    return (
        <div className='container about-page'>
            <div className='text-zone'>
                <h1>
                    About Me
                </h1>
            </div>
        </div>
    )
}

export default AboutIntro;