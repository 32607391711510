import { useRef, useState } from 'react';
import './Contact.scss';
import emailjs from '@emailjs/browser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

const Contact = () => {
    const form = useRef();
    const [overlayVisible, setOverlayVisible] = useState(false);
    const [messageStatus, setMessageStatus] = useState('');
    const [isMobile, setIsMobile] = useState(window.innerWidth < 769);

    const handleResize = () => {
        setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    const sendEmail = (e) => {
        e.preventDefault();
        setOverlayVisible(true);

        emailjs
            .sendForm('service_gb6a98p', 'template_tit6jp9', form.current, {
                publicKey: 'WnXHPz3iW0kDjjrMQ',
            })
            .then(
                () => {
                    setMessageStatus('Message sent successfully!');
                    setTimeout(() => setOverlayVisible(false), 5000);
                    window.location.reload(false);
                    console.log('SUCCESS!');
                },
                (error) => {
                    setMessageStatus('Failed to send. Please try again.');
                    setTimeout(() => setOverlayVisible(false), 5000);
                    console.log('FAILED...', error.text);
                },
            );
    };

    return (
        <>
            <div className='contact-section'>
                {overlayVisible && (
                    <div className="overlay">
                        <p>{messageStatus}</p>
                    </div>
                )}
                <div className="text-section">
                    <h1>Contact Me</h1>
                    <p>Let's connect! Please feel free to reach out using the form, or click About Me to learn more.</p>
                    <button className="flat-button" onClick={() => window.open("https://www.linkedin.com/in/christopher-leu-28bba6147/", "_blank")}>
                        <FontAwesomeIcon icon={faLinkedinIn} color="#00dff9" />
                    </button>
                    <button
                        className="flat-button" onClick={() => window.location.href = '/about'}>
                        About Me
                    </button>
                    <button
                        className="flat-button">
                        Send a Message <FontAwesomeIcon icon={isMobile ? faArrowDown : faArrowRight} color="#00dff9" />
                    </button>
                </div>
                <div className='contact-form'>
                    <form ref={form} onSubmit={sendEmail}>
                        <ul>
                            <li className='half'>
                                <input type="text" name='from_name' placeholder='Name' required />
                            </li>
                            <li className='half'>
                                <input type='email' name='user_email' placeholder='Email' required />
                            </li>
                            <li>
                                <input type='text' name='subject' placeholder='Subject' required />
                            </li>
                            <li>
                                <textarea name='message' placeholder='Message' required></textarea>
                            </li>
                            <li>
                                <input type='submit' className='flat-button' value="SEND" />
                            </li>
                        </ul>
                    </form>
                </div>
            </div>
        </>
    );
};

export default Contact