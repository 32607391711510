import React from "react";
import './Projects.scss';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare, faMagnifyingGlassPlus } from '@fortawesome/free-solid-svg-icons';

const ProjectCard = ({ imgUrl, title, description, url }) => {
    return (
        <div className="projectcard">
            <div className="image-container">
                <img src={imgUrl} alt={title} />
                <div className="overlay">
                    <Link to={url} target="_blank" className="link-icon">
                        <FontAwesomeIcon icon={faArrowUpRightFromSquare} color="#fff" />
                    </Link>
                    <Link to={url} target="_blank" className="link-icon">
                        <FontAwesomeIcon icon={faMagnifyingGlassPlus} color="#fff" />
                    </Link>
                </div>
            </div>
            <div className="text-container">
                <h5>{title}</h5>
                <p className="description">{description}</p>
            </div>
        </div>
    );
};

export default ProjectCard;