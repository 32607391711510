import React from 'react';
import SkillsCategory from './SkillsCategory';
import './Skills.scss';

import javascriptLogo from '../../assets/images/javascriptLogo.svg';
import typescriptLogo from '../../assets/images/typescriptLogo.svg';
import htmlLogo from '../../assets/images/htmlLogo.svg';
import cssLogo from '../../assets/images/cssLogo.svg';
import sassLogo from '../../assets/images/sassLogo.svg';
import bootstrapLogo from '../../assets/images/bootstrapLogo.svg';
import javaLogo from '../../assets/images/javaLogo.svg';
import springLogo from '../../assets/images/springLogo.svg';
import pythonLogo from '../../assets/images/pythonLogo.svg';
import cLogo from '../../assets/images/cLogo.svg';
import nodejsLogo from '../../assets/images/nodejsLogo.svg';
import neo4jLogo from '../../assets/images/neo4jLogo.svg';
import mongodbLogo from '../../assets/images/mongodbLogo.svg';
import postgresqlLogo from '../../assets/images/postgresqlLogo.svg';
import mysqlLogo from '../../assets/images/mysqlLogo.svg';
import rLogo from '../../assets/images/rLogo.svg';
import githubLogo from '../../assets/images/githubLogo.svg';
import dockerLogo from '../../assets/images/dockerLogo.svg';
import kubernetesLogo from '../../assets/images/kubernetesLogo.svg';
import azureLogo from '../../assets/images/azureLogo.svg';
import postmanLogo from '../../assets/images/postmanLogo.svg';
import swaggerLogo from '../../assets/images/swaggerLogo.svg';

const Skills = () => {
    const frontEndSkills = [
        { name: 'JavaScript', logo: javascriptLogo },
        { name: 'TypeScript', logo: typescriptLogo },
        { name: 'HTML', logo: htmlLogo },
        { name: 'CSS', logo: cssLogo },
        { name: 'Sass', logo: sassLogo },
        { name: 'Bootstrap', logo: bootstrapLogo }
    ];

    const backEndSkills = [
        { name: 'Java', logo: javaLogo },
        { name: 'Spring', logo: springLogo },
        { name: 'Python', logo: pythonLogo },
        { name: 'C', logo: cLogo },
        { name: 'NodeJS', logo: nodejsLogo },
    ];

    const databaseSkills = [
        { name: 'Neo4j', logo: neo4jLogo },
        { name: 'MongoDB', logo: mongodbLogo },
        { name: 'PostgreSQL', logo: postgresqlLogo },
        { name: 'MySQL', logo: mysqlLogo },
        { name: 'R', logo: rLogo },
    ];

    const toolsSkills = [
        { name: 'Github', logo: githubLogo },
        { name: 'Docker', logo: dockerLogo },
        { name: 'Kubernetes', logo: kubernetesLogo },
        { name: 'Azure', logo: azureLogo },
        { name: 'Postman', logo: postmanLogo },
        { name: 'Swagger', logo: swaggerLogo },
    ];

    return (
        <>
            <div className="skills-head">
                <h1>SKILLS</h1>
                <h4>Languages, Frameworks & Libraries</h4>
            </div>
            <div className="skills-dashboard">
                <SkillsCategory title="FRONT END" skills={frontEndSkills} />
                <SkillsCategory title="BACK END" skills={backEndSkills} />
                <SkillsCategory title="DATA MANAGEMENT" skills={databaseSkills} />
                <SkillsCategory title="TOOLS" skills={toolsSkills} />
            </div>
        </>
    );
};

export default Skills;