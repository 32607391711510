import React from 'react';
import AboutIntro from './AboutIntro/AboutIntro';
// import Experience from './Experience/Experience';
// import Interests from './Interests/Interest';

const AboutPage = () => {
    return (
        <>
            <AboutIntro />
            {/* <Experience />
            <Interests /> */}
        </>
    )
}

export default AboutPage;